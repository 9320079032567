<template>
  <div class="text-center">
    <v-dialog v-model="dialogInterno" width="600" persistent>
      <v-card>
        <v-card-title class="lighten-2">
          {{ title }}
        </v-card-title>

        <v-card-text class="text-subtitle-1">
          O número do whatsapp do Simplificador mudou em 13/05/2024
          <br />

          Salve agora mesmo o nosso novo número em seus contatos!
          <a
            href="https://wa.me//5511975386175?text=Preciso%20tirar%20dúvidas%20com%20a%20equipe%20do%20Simplificador"
          >
            <b>(11)975386175 </b>
          </a>
          <br />
          <br />
          O antigo foi desativado permanentemente =(
          <br />
          Pedimos desculpas pelo transtorno mas estamos à disposição no novo
          número
          <br />

          <v-card-actions class="text-right mt-6">
            <v-row justify="end">
              <v-btn class="text-right" color="success" @click="changePhone()">
                estou ciente
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Aviso',
    },
  },
  data() {
    return {
      dialogInterno: false,
      loading: false,
    }
  },
  methods: {
    changePhone() {
      this.saveState()
      this.loading = false
      this.dialogInterno = false
    },
    getSavedState() {
      return JSON.parse(window.localStorage.getItem('newWhatsapp'))
    },

    saveState() {
      window.localStorage.setItem('newWhatsapp', true)
    },
  },
  async created() {
    console.log(!!this.getSavedState())
    this.dialogInterno = !this.getSavedState()
    this.loading = false
  },
}
</script>
